<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">班级档案查询</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl"
          :style="
            dialogVisible == true
              ? 'border-bottom: 1px solid #f5f5f6'
              : 'border-bottom:none'
          "
        >
          <div class="searchbox">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">班级编码:</span>
              <el-input
                v-model="projectCode"
                type="text"
                size="small"
                clearable
                placeholder="请输入班级编码"
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                type="text"
                size="small"
                clearable
                placeholder="请输入班级名称"
                style="flex: 1"
              />
            </div>
            <div title="单位名称" class="searchboxItem ci-full">
              <span class="itemLabel">单位名称:</span>
              <el-select
                size="small"
                v-model="ruleForm.unitCompId"
                remote
                :remote-method="getuserCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
                style="width: 100%"
              >
                <el-option
                  v-for="item in userCompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div>
              <!-- <el-button class="bgc-bv" round @click="getData()" size="small">查询</el-button> -->
              <transition name="el-zoom-in-center">
                <el-button
                  type="primary"
                  class="bgc-bv"
                  round
                  @click="getData()"
                  v-show="dialogVisible"
                  >查询</el-button
                >
              </transition>
            </div>
            <div style="margin-left: 10px">
              <el-button type="text" round @click="search()" size="small">
                高级搜索
                <i v-if="dialogVisible" class="el-icon-bottom"></i>
                <i v-else class="el-icon-top"></i>
              </el-button>
            </div>
          </div>
          <div class="btnBox">
            <!-- <el-button class="bgc-bv" round @click="doExport()">导出</el-button> -->
            <!-- <el-button class="bgc-bv" round @click="open" size="small"
              >自定义表头</el-button
            > -->
          </div>
        </div>
        <el-collapse-transition>
          <div v-show="!dialogVisible">
            <div class="operationControl" style="border-bottom: none">
              <div class="searchbox">
                <div title="负责人电话" class="searchboxItem ci-full">
                  <span class="itemLabel projectUser">负责人电话:</span>
                  <el-input
                    v-model="ruleForm.projectUserPhone"
                    type="text"
                    size="small"
                    placeholder="请输入负责人电话"
                    clearable
                  />
                </div>

                <div title="培训状态" class="searchboxItem ci-full">
                  <span class="itemLabel">培训状态:</span>
                  <el-select
                    v-model="ruleForm.studystate"
                    placeholder="请选择培训状态"
                    size="small"
                    clearable
                  >
                    <el-option
                      v-for="item in studylist"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
                <div title="培训类型" class="searchboxItem ci-full">
                  <span class="itemLabel">培训类型:</span>
                  <tree
                    ref="tree"
                    @eventBtn="childBack"
                    :ruleForm="ruleForm"
                    size="small"
                    :modal="false"
                    typeStu
                    @clearParams="clearParams"
                  />
                </div>
                 <div title="行政区划" class="searchboxItem">
                  <span class="itemLabel" style="min-width: 6rem"
                    >行政区划:</span
                  >
                  <el-cascader
                    clearable
                    filterable
                    v-model="ruleForm.areaId"
                    :options="areaTreeList"
                    :props="propsArea"
                    size="small"
                  ></el-cascader>
                </div>
              </div>
            </div>
            <div class="operationControl">
              <div class="searchbox">
               
                <div title="开班时间" class="searchboxItem">
                  <span class="itemLabel" style="min-width:6rem">开班时间 :</span>
                  <el-date-picker
                    v-model="ruleForm.date"
                    size="small"
                    type="daterange"
                    style="width: 5rem"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="timestamp"
                  />
                </div>
                <div title="结束时间" class="searchboxItem">
                  <span class="itemLabel" style="min-width: 6rem"
                    >结束时间:</span
                  >
                  <el-date-picker
                    v-model="ruleForm.dateend"
                    size="small"
                    type="daterange"
                    style="width: 5rem"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="timestamp"
                  />
                </div>
                <div class="df">
                  <el-button
                    type="primary"
                    class="bgc-bv"
                    round
                    @click="getsearch"
                    >查询</el-button
                  >
                </div>
                <div class="df" style="margin-left: 10px">
                  <el-button type="text" round @click="resetForm"
                    >清空搜索条件</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </el-collapse-transition>

        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              v-if="showtable"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="班级编码"
                fixed
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                min-width="200"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                min-width="200"
              />
              <el-table-column
                v-for="(el, index) in tableHaed"
                :key="index"
                :label="el.label"
                :align="el.textAlign"
                show-overflow-tooltip
                :min-width="el.width"
              >
                <template slot-scope="scope">
                  {{ getValue(el, scope.row) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="left" width="370px" fixed="right">
                <div slot-scope="scope" >
                   <el-button
                    type="text"
                    size="mini"
                    style="padding:0 15px"
                    @click="exportStudyDetail(scope.row.projectId,scope.row.projectName) "
                  >学习明细</el-button>
<!--                  <el-popover-->
<!--                    placement="bottom"-->
<!--                    trigger="click">-->
<!--                    <p style="text-align: center;">选择下载格式</p>-->
<!--                    <div style="text-align: center;">-->
<!--                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E50', '10') ">excel</el-button>-->
<!--                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E50', '20') ">pdf</el-button>-->
<!--                    </div>-->
<!--                    <el-button slot="reference" :disabled="!scope.row.allowPrint" size="mini" type="text">学习记录表</el-button>-->
<!--                  </el-popover>-->
                  <el-popover
                    placement="bottom"
                    trigger="click">
                    <p style="text-align: center;">选择下载格式</p>
                    <div style="text-align: center;">
                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E10', '10') ">excel</el-button>
                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E10', '20') ">pdf</el-button>
                    </div>
                    <el-button slot="reference" :disabled="!scope.row.allowPrint" size="mini" type="text">学员注册表</el-button>
                  </el-popover>
                  <el-popover
                    placement="bottom"
                    trigger="click">
                    <p style="text-align: center;">选择下载格式</p>
                    <div style="text-align: center;">
                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E20', '10') ">excel</el-button>
                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E20', '20') ">pdf</el-button>
                    </div>
                    <el-button slot="reference" :disabled="!scope.row.allowPrint" size="mini" type="text">学员签到表</el-button>
                  </el-popover>
                  <!-- <el-popover
                    placement="bottom"
                    trigger="click">
                    <p style="text-align: center;">选择下载格式</p>
                    <div style="text-align: center;">
                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E30', '10') ">excel</el-button>
                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E30', '20') ">pdf</el-button>
                    </div>
                    <el-button slot="reference" :disabled="!scope.row.allowPrint" size="mini" type="text">学员考核表</el-button>
                  </el-popover> -->
                  <el-popover
                    placement="bottom"
                    trigger="click">
                    <p style="text-align: center;">选择下载格式</p>
                    <div style="text-align: center;">
                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E40', '10') ">excel</el-button>
                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E40', '20') ">pdf</el-button>
                    </div>
                    <el-button slot="reference" :disabled="!scope.row.allowPrint" size="mini" type="text">答疑记录表</el-button>
                  </el-popover>
                  <el-popover
                    placement="bottom"
                    trigger="click">
                    <p style="text-align: center;">选择下载格式</p>
                    <div style="text-align: center;">
                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E60', '10') ">excel</el-button>
                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E60', '20') ">pdf</el-button>
                    </div>
                    <el-button slot="reference" :disabled="!scope.row.allowPrint" size="mini" type="text">学习情况汇总表</el-button>
                  </el-popover>
                  <el-popover
                          placement="bottom"
                          trigger="click">
                      <p style="text-align: center;">选择下载格式</p>
                      <div style="text-align: center;">
                          <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E130', '10') ">excel</el-button>
                          <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E130', '20') ">pdf</el-button>
                      </div>
                      <el-button slot="reference" :disabled="!scope.row.allowPrint" size="mini" type="text">学习记录汇总表</el-button>
                  </el-popover>
                  <el-popover
                    placement="bottom"
                    trigger="click">
                    <p style="text-align: center;">选择下载格式</p>
                    <div style="text-align: center;">
                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E70', '10') ">excel</el-button>
                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E70', '20') ">pdf</el-button>
                    </div>
                    <el-button slot="reference" :disabled="!scope.row.allowPrint" size="mini" type="text">考核记录表</el-button>
                  </el-popover>
                  <el-popover
                    placement="bottom"
                    trigger="click">
                    <p style="text-align: center;">选择下载格式</p>
                    <div style="text-align: center;">
                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E80', '10') ">excel</el-button>
                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E80', '20') ">pdf</el-button>
                    </div>
                    <el-button slot="reference" :disabled="!scope.row.allowPrint" size="mini" type="text">考试试卷</el-button>
                  </el-popover>
                  <el-popover
                          placement="bottom"
                          trigger="click">
                      <p style="text-align: center;">选择下载格式</p>
                      <div style="text-align: center;">
                          <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E110', '10') ">excel</el-button>
                          <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E110', '20') ">pdf</el-button>
                      </div>
                      <el-button slot="reference" :disabled="!scope.row.allowPrint" size="mini" type="text">学习记录汇总(按登录)</el-button>
                  </el-popover>
                  <el-popover
                          placement="bottom"
                          trigger="click">
                      <p style="text-align: center;">选择下载格式</p>
                      <div style="text-align: center;">
                          <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E120', '10') ">excel</el-button>
                          <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E120', '20') ">pdf</el-button>
                      </div>
                      <el-button slot="reference" :disabled="!scope.row.allowPrint" size="mini" type="text">学习记录汇总(按课时)</el-button>
                  </el-popover>
<!--                  <el-popover-->
<!--                    placement="bottom"-->
<!--                    trigger="click"-->
<!--                    v-if="btnIsShow(scope.row.startDate)">-->
<!--                    <p style="text-align: center;">选择下载格式</p>-->
<!--                    <div style="text-align: center;">-->
<!--                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E90', '10') ">excel</el-button>-->
<!--                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E90', '20') ">pdf</el-button>-->
<!--                    </div>-->
<!--                    <el-button slot="reference" :disabled="!scope.row.allowPrint" size="mini" type="text">学习轨迹</el-button>-->
<!--                  </el-popover>-->
<!--                  <el-popover-->
<!--                    placement="bottom"-->
<!--                    trigger="click"-->
<!--                    v-if="btnIsShow(scope.row.startDate)">-->
<!--                    <p style="text-align: center;">选择下载格式</p>-->
<!--                    <div style="text-align: center;">-->
<!--                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E100', '10') ">excel</el-button>-->
<!--                      <el-button size="mini" type="text" @click="exportProjectDetail(scope.row.projectId, 'SHANGHAI_E100', '20') ">pdf</el-button>-->
<!--                    </div>-->
<!--                    <el-button slot="reference" :disabled="!scope.row.allowPrint" size="mini" type="text">学员档案</el-button>-->
<!--                  </el-popover>-->
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
      <el-dialog
        title="自定义表头"
        :visible.sync="tableHeadDialog"
        width="30%"
        class="custom"
        @close="cencal"
      ><p style="padding-left: 20px; margin-bottom: 10px">
          勾选需要显示在列表上的项，可拖动调整顺序
        </p>
        <div>
          <el-tree
            ref="headTree"
            :data="treeDataCopy"
            default-expand-all
            draggable
            :allow-drop="allowDrop"
            :allow-drag="allowDrag"
          >
            <template slot-scope="{ node, data }">
              <el-checkbox
                @change="
                  (val) => {
                    handleCheckChange(val, data);
                  }
                "
                v-model="data.checked"
                :disabled="data.label == '课程名称'"
                >{{ node.label }}</el-checkbox
              >
            </template>
          </el-tree>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cencal">取 消</el-button>
          <el-button class="bgc-bv" @click="resetHeadOk">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import tree from "@/components/treePopup";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "set/openingStatistics",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      userCompanyList: [],
      showtable: false,
      dialogVisible: true,
      projectCode: "",
      projectName: "",
      studylist: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "50",
          label: "培训中",
        },
        {
          value: "60",
          label: "培训结束",
        },
      ],
      totalProjectNum: 0,
      endProjectNum: 0,
      ruleForm: {
        studystate: "",
        Trainingtype: "",
        date: [],
        dateend: [],
        unitCompId: "",
        areaId: "",
      },
      params: {},
      tableHeadDialog: false, //自定义表头弹窗
      treeData: [
        {
          label: "培训类型", //表头名称
          propName: "trainType", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "140", // 列的宽
          order: 1,
          id: "1",
          checked: true,
        },
        {
          label: "开班时间", //表头名称
          propName: "startDate", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 2,
          id: "2",
          checked: true,
        },
        {
          label: "结束时间", //表头名称
          propName: "endDate", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 3,
          id: "3",
          checked: true,
        },
        {
          label: "计划培训人数", //表头名称
          propName: "projectPeople", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "nummer", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 4,
          id: "4",
          checked: true,
        },
        {
          label: "学员总数", //表头名称
          propName: "userTotalCount", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "nummer", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 5,
          id: "5",
          checked: true,
        },
        {
          label: "学时完成人数", //表头名称
          propName: "userCompleteCount", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "nummer", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 6,
          id: "6",
          checked: true,
        },
        {
          label: "培训状态", //表头名称
          propName: "trainState", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "PROJECTSTATE", //字典对应key
          width: "120", // 列的宽
          order: 7,
          id: "7",
          checked: true,
        },
        {
          label: "负责人", //表头名称
          propName: "projectUser", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 8,
          id: "8",
          checked: true,
        },
        {
          label: "负责人电话", //表头名称
          propName: "projectUserPhone", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 9,
          id: "9",
          checked: true,
        },
        {
          label: "要求总学时", //表头名称
          propName: "lessonNumDemand", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "nummer", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 10,
          id: "10",
          checked: true,
        },
        {
          label: "岗位类型", //表头名称
          propName: "post", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 11,
          id: "11",
          checked: true,
        },
        {
          label: "行业类型", //表头名称
          propName: "industry", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 12,
          id: "12",
          checked: true,
        },

        {
          label: "职业/工种", //表头名称
          propName: "occupation", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 13,
          id: "13",
          checked: true,
        },
        {
          label: "培训等级", //表头名称
          propName: "trainLevel", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 14,
          id: "14",
          checked: true,
        },
        {
          label: "单位名称", //表头名称
          propName: "compName", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "190", // 列的宽
          order: 15,
          id: "15",
          checked: true,
        },
        {
          label: "结业人数", //表头名称
          propName: "projectCompleteCount", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "nummer", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 16,
          id: "16",
          checked: true,
        },
        {
          label: "行政区划", //表头名称
          propName: "areaNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "180", // 列的宽
          order: 17,
          id: "17",
          checked: true,
        },
      ],
      tableHaed: [],
      treeDataCopy: [],
      areaTreeList: [], //行政区划
      propsArea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      // 操作气泡
      popoverVisible: false,
    };
  },
  created() {
    this.getTableHeight();
    this.getTableHead();
    this.getAreaTree();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {},
  methods: {
    init() {
      this.getData();
    },
    //如果开班时间大于等于2023则不显示按钮
    // btnIsShow(date){
    //     console.log('date',date.substring(0,4))
    //     if(date.substring(0,4)>=2023){
    //         return false
    //     }else{
    //         return true
    //     }
    // },
    /* 单位名称 */
    getuserCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyFJList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.userCompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.userCompanyList = [];
      }
    },
    // 导出
    doExport() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const params = {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            projectCode: this.projectCode,
            projectName: this.projectName || null,
          };
          if (this.ruleForm.projectUserPhone) {
            params.projectUserPhone = this.ruleForm.projectUserPhone;
          }
          if (this.ruleForm.date) {
            params.startDateBegin = this.ruleForm.date[0];
            params.startDateEnd = this.ruleForm.date[1];
          }
          if (this.ruleForm.areaId) {
            params.areaId = this.ruleForm.areaId;
          }
          if (this.ruleForm.dateend) {
            params.endDateBegin = this.ruleForm.dateend[0];
            params.endDateEnd = this.ruleForm.dateend[1];
          }
          if (this.ruleForm.studystate) {
            params.trainState = this.ruleForm.studystate;
          }
          if (this.ruleForm.unitCompId) {
            params.compNameId = this.ruleForm.unitCompId;
          }
          if (this.params.trainFormId) {
            params.trainTypeId = this.params.trainFormId;
          }
          if (this.params.levelFormId) {
            params.trainLevelId = this.params.levelFormId;
          }
          if (this.params.occFormId) {
            params.occupationId = this.params.occFormId;
          }
          if (this.params.postFormId) {
            params.postId = this.params.postFormId;
          }
          if (this.params.industryFormId) {
            params.industryId = this.params.industryFormId;
          }
          this.$post("/biz/report/project/async/export", params)
            .then((res) => {
              console.log(res.status);
              if (res.status == "0") {
                let list = [];
                list.push(res.data);
                for (let item of list) {
                  console.log(item);
                  if (!this.downloadItems.includes(item.taskId)) {
                    this.$store.dispatch("pushDownloadItems", item.taskId);
                  } else {
                    this.$message.warning(
                      "[" + item.fileName + "]已经申请下载,请耐心等待"
                    );
                  }
                }
              } else {
                this.$message.error(re.message);
              }
            })
            .catch((err) => {
              return;
            });
        })
        .catch(() => {});
    },
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    //获取行政区划
    getAreaTree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areaTreeList = ret.data;
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.ruleForm.projectUserPhone) {
        params.projectUserPhone = this.ruleForm.projectUserPhone;
      }
      if (this.ruleForm.areaId) {
        params.areaId = this.ruleForm.areaId;
      }
      if (this.ruleForm.date) {
        params.startDateBegin = this.ruleForm.date[0];
        params.startDateEnd = this.ruleForm.date[1];
      }
      if (this.ruleForm.dateend) {
        params.endDateBegin = this.ruleForm.dateend[0];
        params.endDateEnd = this.ruleForm.dateend[1];
      }
      if (this.ruleForm.studystate) {
        params.trainState = this.ruleForm.studystate;
      }
      if (this.ruleForm.unitCompId) {
        params.compNameId = this.ruleForm.unitCompId;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/biz/report/project/record",
        params,
        pageNum,
      });
    },
   
    getsearch() {
      this.dialogVisible = false;
      this.getData();
    },
    // 高级搜索弹窗
    search() {
      this.dialogVisible = !this.dialogVisible;
      this.getTableHeight();
    },
    //清空搜索条件
    resetForm() {
      // this.$refs[formName].resetFields();
      this.params = {};
      this.ruleForm = {};
      // this.dialogVisible = true;
      this.projectCode = "";
      this.projectName = "";
      // this.getTableHeight();
    },
    //取消
    cancleForm(formName) {
      this.dialogVisible = false;
      this.params = {};
      this.resetForm("ruleForm");
    },
    doClose() {
      this.dialogVisible = false;
      this.$refs.tree.clear(); // 高级搜索关闭，清空培训类型数据
      this.ruleForm = {
        studystate: "",
        Trainingtype: "",
        date: "",
      };
      this.params = {};
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -=
          this.dialogVisible == true
            ? 40 + 0.675 * 16 + 1 + 25
            : 80 + 40 + 0.675 * 16 + 1 + 25;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
    // 判断拖拽的位置
    allowDrop(draggingNode, dropNode, type) {
      return type !== "inner";
    },
    // 节点是否可以拖拽
    allowDrag(draggingNode) {
      if (
        draggingNode.label == "班级编码" ||
        draggingNode.label == "班级名称"
      ) {
        return false;
      }
      return true;
    },
    // 根据字段转换页面展示的内容
    getValue(el, row) {
      // 状态
      if (el.renderType == "status") {
        return this.$setDictionary(el.dictionaries, row[el.propName]);
      } else if (el.renderType == "time") {
        // 时间
        if (el.timeType == "moment") {
          return row[el.propName]
            ? this.$moment(row[el.propName]).format("YYYY-MM-DD HH:mm:ss")
            : "--";
        } else if (el.timeType == "custom") {
          return this.getTime(row[el.propName]);
        } else {
          return row[el.propName];
        }
      } else if (el.renderType == "nummer") {
        return row[el.propName] || "0";
      } else {
        // 其他
        return row[el.propName] || "--";
      }
    },
    // 自定义确定
    resetHeadOk() {
      let list = [];
      let saveData = [];
      this.treeDataCopy.forEach((el, index) => {
        saveData.push({ id: el.id, order: index, checked: el.checked });
        if (el.checked) {
          list.push(el);
        }
      });
      this.tableHaed = list;
      this.tableHeadDialog = false;
      this.$post("/sys/admin/savePageConfig", {
        configInfo: JSON.stringify(saveData),
        pageName: "openingstatistics",
      }).then((res) => {
        this.getTableHead();
      });
    },
    //自定义表头取消
    open() {
      this.tableHeadDialog = true;
      this.handle();
    },
    cencal() {
      this.tableHeadDialog = false;
    },
    getTableHead() {
      this.$post("/sys/admin/findAdminConfig", {
        pageName: "openingstatistics",
      })
        .then((res) => {
          if (res.status == 0) {
            const data = res.data || {};
            let list = [];
            if (data.configInfo && data.configInfo != "") {
              list = JSON.parse(data.configInfo);
            }
            if (list.length) {
              list.map((el) => {
                this.treeData.forEach((it, index) => {
                  if (el.id == it.id) {
                    it.checked = el.checked;
                    it.order = el.order;
                  }
                });
              });
              this.treeData.sort((a, b) => {
                return a.order - b.order;
              });
              this.handle();
            } else {
              // this.tableHaed = [...this.treeData];
              this.handle();
            }
          } else {
            // this.tableHaed = [...this.treeData];
            this.handle();
          }
        })
        .catch((err) => {
          this.tableHaed = [...this.treeData];
        });
    },
    handle() {
      this.tableHaed = [];
      this.treeDataCopy = [];
      this.treeData.forEach((el) => {
        this.treeDataCopy.push(Object.assign({}, el));
      });
      this.treeDataCopy.forEach((el) => {
        if (el.checked) {
          this.tableHaed.push(el);
        }
      });
      this.showtable = true;
    },
    handleCheckChange(val, data) {},
    //导出明细
    exportProjectDetail(projectId, fileType,downloadFileType) {
      this.$post('/sys/download/shanghai/api',{
            "identifier": fileType,
            "projectId" : projectId,
             downloadFileType
          }).then(res => {
            if(res.status == '0') {
                  for (let item of res.data) {
                  console.log(item);
                  if (!this.downloadItems.includes(item.taskId)) {
                    this.$store.dispatch("pushDownloadItems", item.taskId);
                  } else {
                    this.$message.warning(
                      "[" + item.fileName + "]已经申请下载,请耐心等待"
                    );
                  }
                } 
            } else {
                this.$message.error(re.message);
              }
          
      })
    },
    //学员学习明细
    exportStudyDetail(projectId,projectName) {
      this.$post('/biz/report/project/exportProjectDetail',{projectId,projectName}).then(res => {
       if (res.status == "0") {
          let list = res.data;
          if (!this.downloadItems.includes(list.taskId)) {
            this.$store.dispatch("pushDownloadItems", list.taskId);
          } else {
            this.$message.warning(
              "[" + list.fileName + "]已经申请下载,请耐心等待"
            );
          }
        } else {
          this.$message.error(res.message);
        }
      })
    }
  },
};
</script>

<style lang="less" scope>
.projectUser {
  min-width: 6rem !important;
}

.custom {
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #5c6be8;
    border-color: #5c6be8;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #5c6be8;
  }
}
</style>
